const ProductData = [
  {
    id: 1,
    title: "Одностропный МКР",
    img: "../img/1-line.png",
    description: {
      text:
        "Одностропный мягкий контейнер, изготавливается из рукавной полипропиленовой ткани и, как можно понять из названия, имеет всего одну стропу. Данный вид контейнеров нашёл своё основное применение в химической промышленности. Стропа у данного биг-бэга является продолжением самого контейнера и в верхней точке обёрнута цветной ламинированной манжетой, которая защищает стропу от повреждения и легко опознается оператором крана, погрузчика и т.д., для более лёгкого захватывания стропы.",
      loadCapacity: "от 500кг до 2 тонн",
      size: "стандартные и по индивидуальным заявкам",
      volume: "стандартные и по индивидуальным заявкам",
      cost: "от 250р.",
    },
  },
  {
    id: 2,
    title: "Двухстропный МКР",
    img: "../img/2-line.png",
    description: {
      text:
        "Биг-Бэг двухстропный является самым экономичным и востребованным среди других видов биг-бэгов. Конструктивно представляет собой большой прочный мешок с двумя грузоподъёмными элементами, которые являются продолжением самого Биг-Бэга и называются стропами. Этот биг-бэг широко используется во многих отраслях, таких как химическая и пищевая промышленность, сельское хозяйство, строительство и др. Стропы у двухстропных мягких контейнеров, являются продолжением тела самого биг бэга и имеют обвязку из ламинированного полипропилена, для предотвращения протирания стропы ",
      loadCapacity: "от 500кг до 2 тонн",
      size: "стандартные и по индивидуальным заявкам",
      volume: "стандартные и по индивидуальным заявкам",
      cost: "от 250р.",
    },
  },
  {
    id: 3,
    title: "Четырёхстропный МКР",
    img: "../img/4-lines.png",
    description: {
      text:
        "Четырёхстропный биг-бэг представляет собой универсальную тару, которая востребована практически во всех отраслях. Универсальным этот мягкий контейнер делает то, что его можно изготовить со множеством различных характеристик, его можно сделать разным по форме, объёму, грузоподъёмности, добавить в комплектацию сборку, люк или крышку, пришить дополнительные стропы, сделать их разными по высоте и т.д. То есть этот биг-бэг можно полностью адаптировать под нужды предприятия. Вкладыш для мягкого контейнера представляет собой большой полиэтиленовый пакет, изготовленный из полиэтилена высокого (ПВД) или низкого давления (ПНД), который вкладывается внутрь биг-бэга автоматическим или ручным способами. Его применение является обязательным для очень большой группы товаров. В этот список в основном входит продукция для которой намокание или даже повышение влажности является критичной, например: цемент, сухие смеси, различные виды химической продукции, соль, сахар и др.",
      loadCapacity: "от 500кг до 2 тонн",
      size: "стандартные и по индивидуальным заявкам",
      volume: "стандартные и по индивидуальным заявкам",
      cost: "от 370р.",
    },
  },
];

export default ProductData;
